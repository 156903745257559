export default {
  lab_results: {
    search: null,
    filter: {},
    sortBy: ['created_at'],
    sortDesc: [true],
  },
  prescriptions: {
    search: null,
    filter: {},
    sortBy: ['order_date'],
    sortDesc: [true],
  },
}
