import Vue from 'vue'
import VuetifyDialog from 'vuetify-dialog'
import 'vuetify-dialog/dist/vuetify-dialog.css'

Vue.use(VuetifyDialog, {
    warning: {
      actions: {
        false: {
          text: 'No',
          outlined: true,
        },
        true: {
          text: 'Yes',
          color: 'primary',
          outlined: true,
        },
      },
    },
    confirm: {
      icon: 'fal fa-question',
      actions: {
        false: {
          text: 'Cancel',
        },
        true: {
          text: 'Confirm',
          color: 'primary',
          elevation: 2,
          outlined: true,
        },
      },
    },
})
