import { set } from '@/utils/vuex'

export default {
  setLoading(state, { key, value }) {
    state.loading[key] = value
  },
  setMonthlyChartData: set('lab_results_monthly'),
  setWeeklyChartData: set('lab_results_weekly'),
  setDailyChartData: set('lab_results_daily'),
  setSourceChartData: set('sources'),
  setActivityChartData: set('activity'),
  setApprovalTimeChartData: set('approval_time'),
  setStatusesChartData: set('statuses'),
}
