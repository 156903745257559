<template>
  <v-card
    :style="styles"
    v-bind="$attrs"
    :loading="false"
    v-on="$listeners"
  >
    <helper-offset
      v-if="hasOffset"
      :inline="inline"
      :full-width="fullWidth"
      :offset="offset"
    >
      <v-card
        v-if="!$slots.offset"
        :color="color"
        :elevation="elevation"
        class="v-card--material__header d-flex align-center"
        dark
        min-height="80"
      >
        <slot
          v-if="!title && !text"
          name="header"
        />
        <v-row
          v-else
          class="px-3"
          justify="space-between"
        >
          <div
            v-if="$attrs.icon"
            class="align-self-center ml-5 mr-n2"
            cols="shrink"
          >
            <v-icon
              large
            >
              {{ $attrs.icon }}
            </v-icon>
          </div>
          <v-col>
            <v-card-title
              class="text-h6 font-weight-light mb-0"
              v-text="title"
            />
            <v-card-subtitle
              class="category font-weight-thin mb-0"
              v-text="text"
            />
          </v-col>
        </v-row>
      </v-card>

      <slot
        v-else
        name="offset"
      />
    </helper-offset>

    <slot
      v-if="$slots.contents"
      name="contents"
    />
    <v-card-text v-else>
      <slot />
    </v-card-text>

    <v-divider
      v-if="$slots.actions"
      class="mx-3"
    />

    <v-card-actions v-if="$slots.actions">
      <slot name="actions" />
    </v-card-actions>
  </v-card>
</template>

<script>
  export default {
    name: 'MaterialCard',

    inheritAttrs: false,

    props: {
      color: {
        type: String,
        default: 'secondary',
      },
      elevation: {
        type: [Number, String],
        default: 10,
      },
      inline: {
        type: Boolean,
        default: false,
      },
      fullWidth: {
        type: Boolean,
        default: false,
      },
      offset: {
        type: [Number, String],
        default: 24,
      },
      title: {
        type: String,
        default: undefined,
      },
      text: {
        type: String,
        default: undefined,
      },
    },

    computed: {
      hasOffset () {
        return this.$slots.header ||
          this.$slots.offset ||
          this.title ||
          this.text
      },
      styles () {
        if (!this.hasOffset) return null

        return {
          marginBottom: `${this.offset}px`,
          marginTop: `${this.offset * .75}px`,
        }
      },
    },
  }
</script>
