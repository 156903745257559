// https://vuex.vuejs.org/en/state.html

export default {
  loading: {
    lab_results_monthly: false,
    lab_results_weekly: false,
    lab_results_daily: false,
    sources: false,
    activity: false,
    lab_results: false,
    statuses: false,
    approval_time: false,
    revenue: false,
    stats: {
      patients: false,
      microbes: false,
      providers: false,
      facilities: false,
    },
  },
  lab_results_monthly: {
    labels: [],
    series: [
      [],
    ],
  },
  lab_results_weekly: {
    labels: [],
    series: [
      [],
    ],
  },
  lab_results_daily: {
    labels: [],
    series: [
      [],
    ],
  },
  sources: {
    labels: [],
    series: [
      [],
    ],
  },
  activity: {},
  approval_time: {
    response_time: null,
    auto_approval: {},
    interpreted: {},
  },
  statuses: {
    lab_results: {},
    prescriptions: {},
  },
  stats: {},
  mapData: [],
  clientActivity: [],
}
