import Vue from 'vue'
import Vuetify, { VSnackbar, VBtn, VIcon } from 'vuetify/lib'
import VuetifyToast from 'vuetify-toast-snackbar-ng'

Vue.use(Vuetify, {
  components: {
    VSnackbar,
    VBtn,
    VIcon,
  },
})

Vue.use(VuetifyToast, {
    y: 'top',
    queueable: true,
    closeIcon: 'mdi-close',
    timeout: 3500,
    shorts: {
        error: {
            icon: 'fal fa-exclamation-triangle',
            color: 'error',
        },
        success: {
            icon: 'fal fa-check-circle',
            color: 'success',
        },
        info: {
            icon: 'fal fa-info-square',
            color: 'info',
        },
        processing: {
            icon: 'fad fa-spinner fa-pulse',
            color: 'info',
            multiLine: true,
            dismissable: true,
            timeout: -1,
        },
        copy: {
            icon: 'fal fa-copy',
            color: '#4285F4',
        },
    },
})
