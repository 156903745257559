<template>
  <v-app>
    <router-view />
  </v-app>
</template>

<script>
import numeral from 'numeral'

export default {
  metaInfo: {
    title: process.env.VUE_APP_TITLE,
    titleTemplate: '%s | ' + process.env.VUE_APP_TITLE,
  },
  mounted () {
    let usersLanguage = window.navigator.language.toLowerCase()
    let shortLanguage = usersLanguage.split('-')[0]

    // vuetify
    this.$vuetify.lang.current = shortLanguage

    // moment
    this.$moment.locale(usersLanguage)

    // numeral
    numeral.locale(Object.keys(numeral.locales).includes(shortLanguage) ? shortLanguage : 'en')

    // i18n
    if (shortLanguage === 'he') {
      shortLanguage = 'iw'
      this.$vuetify.rtl = true
    } else {
      this.$vuetify.rtl = false
    }
    this.$i18n.locale = shortLanguage
  },
}
</script>
