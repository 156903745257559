import { set, toggle } from '@/utils/vuex'

export default {
  setDrawer: set('drawer'),
  setAdminMenu: set('admin_menu'),
  setImage: set('image'),
  setColor: set('color'),
  toggleDrawer: toggle('drawer'),
  setSettings: set('settings'),
}
