let routes;

const host = window.location.host;
const parts = host.split(".");

if (parts[0] === "report" || parts[0] === "r" || parts[0] === "onechoice") {
  routes = [
    {
      path: "/:hash?",
      component: () => import("@/views/ReportViewer.vue"),
    },
  ];
} else if (parts[0] === "asp") {
  routes = [
    {
      path: "/:hash?",
      component: () => import("@/views/PrescriptionViewer.vue"),
    },
  ];
} else if (parts[0] === "medsmatrix") {
    routes = [
      {
        path: "/:hash?",
        component: () => import("@/views/MedsMatrix.vue"),
      },
    ];
} else {
  routes = [
    {
      path: "/",
      component: () => import("@/views/AppLayout.vue"),
      meta: {
        auth: true,
      },
      children: [
        {
          path: "/",
          redirect: "/dashboard",
        },
        {
          path: "/dashboard",
          name: "Dashboard",
          component: () => import("@/views/Dashboard.vue"),
          meta: {
            icon: "mdi-view-dashboard",
          },
        },
        {
          path: "/user-profile",
          name: "User Profile",
          component: () => import("@/views/UserProfile.vue"),
        },
        {
          path: "/precision-asp",
          name: "Precision ASP",
          component: () => import("@/views/PrecisionASP.vue"),
        },
        {
          path: "",
          component: () => import("@/views/TablePage.vue"),
          children: [
            {
              path: "patients",
              name: "Patients",
              meta: {
                auth: { patients: "phi" },
                title: 'patient',
              },
              components: {
                dataTable: () => import("@/components/tables/PatientsTable.vue"),
              },
            },
            {
              name: "Providers",
              path: "providers",
              components: {
                dataTable: () => import("@/components/tables/ProvidersTable.vue"),
                rightDrawer: () => import("@/components/drawers/ProviderDrawer.vue"),
              },
              meta: {
                auth: { providers: ["view", "edit"] },
                title: 'provider',
              },
            },
            {
              name: "Lab Results",
              path: "lab_results",
              components: {
                dataTable: () => import("@/components/tables/LabResultsTable.vue"),
              },
              meta: {
                auth: { "lab results": ["view", "interpret", "approve"] },
                title: 'Lab Result',
              },
            },
            {
              name: "Prescriptions",
              path: "prescriptions",
              components: {
                dataTable: () => import("@/components/tables/PrescriptionsTable.vue"),
                title: 'prescription',
              },
            },
            {
              name: "Facilities",
              path: "facilities",
              components: {
                dataTable: () => import("@/components/tables/FacilitiesTable.vue"),
                rightDrawer: () => import("@/components/drawers/FacilityDrawer.vue"),
              },
              meta: {
                auth: { facilities: ["view", "edit"] },
                title: 'facility',
              },
            },
            {
              name: "Reports",
              path: "reports",
              components: {
                dataTable: () => import("@/components/tables/ReportsTable.vue"),
              },
              meta: {
                auth: { reports: ["view", "order"] },
                title: 'report',
              },
            },
          ],
        },
        {
          name: "Education",
          path: "education",
          component: () => import("@/views/Education.vue"),
          meta: {
            title: 'education',
          },
        },
        {
          path: "lab_results/:uuid",
          component: () => import("@/views/lab_result/LabResult.vue"),
          meta: {
            auth: { "lab results": "view" },
          },
        },
        {
          path: "lab_results/:uuid/edit",
          name: "Edit Lab Result",
          component: () => import("@/views/lab_result/EditLabResult.vue"),
          meta: {
            auth: { "lab results": "add" },
          },
        },
        {
          path: "lab_results/:uuid/extract",
          name: "Data Extraction",
          component: () => import("@/views/lab_result/Extraction.vue"),
          meta: {
            auth: { "lab results": "extract" },
          },
        },
        {
          path: "lab_results/:uuid/interpretation",
          name: "Interpretation",
          meta: {
            auth: { "lab results": "interpret" },
          },
          component: () => import("@/views/interpretation/Interpret.vue"),
        },
        {
          path: "lab_results/:uuid/interpretation/review",
          name: "Review Interpretation",
          component: () => import("@/views/interpretation/Review.vue"),
        },
        {
          path: "lab_results/:uuid/interpretation/translate",
          name: "Translate Interpretation",
          component: () => import("@/views/interpretation/Translate.vue"),
        },
        {
          path: "reports/:uuid/review",
          name: "Review Report",
          meta: {
            auth: { reports: ["approve"] },
          },
          component: () => import("@/views/ReportReview.vue"),
        },
        {
          path: "reports/:uuid/review",
          name: "Review Report",
          meta: {
            auth: { reports: ["approve"] },
          },
          component: () => import("@/views/ReportReview.vue"),
        },
        {
          path: "reports/:uuid/review",
          name: "Review Report",
          meta: {
            auth: { reports: ["approve"] },
          },
          component: () => import("@/views/ReportReview.vue"),
        },
        {
          path: "/notifications",
          component: () => import("@/views/Notifications.vue"),
        },
      ],
    },
    {
      path: "/admin",
      component: () => import("@/views/AppLayout.vue"),
      redirect: "/dashboard",
      meta: {
        auth: true,
      },
      children: [
        {
          name: "System",
          path: "system",
          component: () => import("@/views/System.vue"),
          meta: {
            auth: { financials: "view", queue: "view" },
          },
        },
        {
          path: "",
          component: () => import("@/views/TablePage.vue"),
          children: [
            {
              name: "Organisms",
              path: "organisms",
              components: {
                dataTable: () => import("@/components/tables/OrganismsTable.vue"),
                rightDrawer: () => import("@/components/drawers/OrganismDrawer.vue"),
                meta: {
                  auth: { organisms: ["view", "edit"] },
                },
              },
            },
            {
              name: "References",
              path: "references",
              components: {
                dataTable: () => import("@/components/tables/ReferencesTable.vue"),
                rightDrawer: () => import("@/components/drawers/ReferencesDrawer.vue"),
                meta: {
                  auth: { references: ["view", "edit"] },
                },
              },
            },
            {
              name: "Resistances",
              path: "resistances",
              components: {
                dataTable: () => import("@/components/tables/ResistancesTable.vue"),
                rightDrawer: () => import("@/components/drawers/ResistanceDrawer.vue"),
              },
              meta: {
                auth: { resistances: ["view", "edit"] },
              },
            },
            {
              name: "Diagnosis Codes",
              path: "diagnoses",
              components: {
                dataTable: () => import("@/components/tables/DiagnosesTable.vue"),
                rightDrawer: () => import("@/components/drawers/DiagnosesDrawer.vue"),
              },
              meta: {
                auth: { diagnoses: ["view", "edit"] },
              },
            },
            {
              name: "Drugs",
              path: "drugs",
              components: {
                dataTable: () => import("@/components/tables/DrugsTable.vue"),
                rightDrawer: () => import("@/components/drawers/DrugDrawer.vue"),
              },
              meta: {
                auth: { drugs: ["view", "edit"] },
              },
            },
            {
              name: "Formularies",
              path: "drugs/formularies",
              components: {
                dataTable: () => import("@/components/tables/FormularyTable.vue"),
              },
            },
            {
              name: "Instructions",
              path: "instructions",
              components: {
                dataTable: () => import("@/components/tables/InstructionTable.vue"),
                rightDrawer: () => import("@/components/drawers/InstructionDrawer.vue"),
              },
            },
            {
              name: "Indications",
              path: "indications",
              components: {
                dataTable: () => import("@/components/tables/IndicationsTable.vue"),
                rightDrawer: () => import("@/components/drawers/IndicationDrawer.vue"),
              },
              meta: {
                auth: { indications: ["view", "edit"] },
              },
            },
            {
              name: "Newsletters",
              path: "newsletters",
              components: {
                dataTable: () => import("@/components/tables/NewslettersTable.vue"),
              },
              meta: {
                auth: { newsletters: ["view", "edit"] },
              },
            },
            {
              name: "Clients",
              path: "clients",
              components: {
                dataTable: () => import("@/components/tables/ClientsTable.vue"),
                rightDrawer: () => import("@/components/drawers/ClientDrawer.vue"),
              },
              meta: {
                auth: { clients: ["view", "edit"] },
                title: 'client',
              },
            },
            {
              name: "Users",
              path: "users",
              components: {
                dataTable: () => import("@/components/tables/UsersTable.vue"),
                rightDrawer: () => import("@/components/drawers/UserDrawer.vue"),
              },
              meta: {
                auth: { users: ["view", "edit"] },
                title: 'user',
              },
            },
            // {
            //   name: "Packages",
            //   path: "packages",
            //   components: {
            //     dataTable: () => import("@/components/tables/PackagesTable.vue"),
            //     rightDrawer: () =>
            //       import("@/components/drawers/PackageDrawer.vue"),
            //   },
            //   meta: {
            //     auth: { packages: ["view", "edit"] },
            //   },
            // },
            // {
            //   name: "Features",
            //   path: "features",
            //   components: {
            //     dataTable: () => import("@/components/tables/FeaturesTable.vue"),
            //     rightDrawer: () =>
            //       import("@/components/drawers/FeatureDrawer.vue"),
            //   },
            //   meta: {
            //     auth: { features: ["view", "edit"] },
            //   },
            // },
          ],
        },
      ],
    },
    {
      path: "/report/:hash?",
      component: () => import("@/views/ReportViewer.vue"),
    },
    {
      path: "/meds_matrix/:hash?",
      component: () => import("@/views/MedsMatrix.vue"),
    },
    {
      path: "/asp/:hash?",
      component: () => import("@/views/PrescriptionViewer.vue"),
    },
    {
      path: "/",
      component: () => import("@/views/AuthLayout.vue"),
      children: [
        {
          path: "login/:type?",
          name: "Login",
          component: () => import("@/views/auth/Login.vue"),
        },
        // {
        //     path: "register",
        //     name: "Register",
        //     component: Register
        // },
        {
          path: "password/:token?",
          name: "Password",
          component: () => import("@/views/auth/Password.vue"),
          // },
          // {
          //     path: "lock",
          //     name: "Lock",
          //     component: Lock
        },
      ],
    },
    {
      path: "/403",
      redirect: "/dashboard",
    },
    {
      path: "*",
      name: "Page Not Found",
      component: () => import("@/views/NotFoundPage.vue"),
    },
  ];
}

export default routes;
