// https://vuex.vuejs.org/en/state.html

export default {
  clients: [],
  facilities: [],
  indications: [],
  lab_results: [],
  organisms: [],
  providers: [],
  services: [],
  tags: {
    client: [],
    facility: [],
    infection: [],
    provider: [],
  },
  locations: {
    states: [],
    counties: [],
    cities: [],
    countries: [],
  },
}
