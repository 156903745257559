// https://vuex.vuejs.org/en/state.html

export default {
  clients: [],
  facilities: [],
  indications: [],
  lab_results: [],
  organisms: [],
  providers: [],
  services: [],
}
