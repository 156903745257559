import Vue from 'vue'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginVue from '@bugsnag/plugin-vue'

Bugsnag.start({
  apiKey: process.env.VUE_APP_BUGSNAG_API_KEY,
  appVersion: process.env.npm_package_version,
  plugins: [
    new BugsnagPluginVue(Vue),
  ],
  enabledReleaseStages: ['production', 'staging'],
  releaseStage: process.env.NODE_ENV,
})
