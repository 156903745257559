import { set } from '@/utils/vuex'

export default {
  setIndications: set('indications'),
  setLabResults: set('lab_results'),
  setOrganisms: set('organisms'),
  setProviders: set('providers'),
  setClients: set('clients'),
  setServices: set('services'),
  setFacilities: set('facilities'),
}
