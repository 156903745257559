import { flare } from "@flareapp/flare-client"
import { flareVue } from "@flareapp/flare-vue"
import Vue from 'vue'

// Only enable Flare in production, we don't want to waste your quota while you're developing:
if (process.env.NODE_ENV === 'production') {
  flare.light()
}

Vue.use(flareVue)
