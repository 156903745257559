import Vue from 'vue'
import VueMoment from 'vue-moment'
import moment from 'moment-timezone'

moment.updateLocale('en', {
  longDateFormat: {
    L: "M/D/YYYY",
    LT: "h:mm a",
  },
});

Vue.use(VueMoment, {
    moment,
})
