// https://vuex.vuejs.org/en/actions.html
import axios from "axios";

export default {
  async fetchIndications({ commit }, { params }) {
    try {
      const data = await axios.get('admin/indications', { params: params })
      commit('SET_INDICATIONS', data.data)
    }
    catch (error) {
      // console.log(error)
    }
  },
}
