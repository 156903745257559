/**
 * Vue Router
 *
 * @library
 *
 * https://router.vuejs.org/en/
 */

// Lib imports
import Vue from 'vue'
import VueRouter from 'vue-router'
import Meta from 'vue-meta'
import VueGtag from 'vue-gtag'

// Routes
import routes from './routes'

Vue.use(VueRouter)

// Create a new router
const router = new VueRouter({
  mode: 'history',
  routes: routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }
    if (to.hash) {
      return { selector: to.hash }
    }
    return { x: 0, y: 0 }
  },
})
Vue.router = router

Vue.use(Meta)

if (process.env.VUE_APP_GOOGLE_GTAG) {
  Vue.use(VueGtag, {
    config: { id: process.env.VUE_APP_GOOGLE_GTAG },
    pageTrackerEnabled: process.env.NODE_ENV !== 'development',
  }, router)
}

export default router
