// https://vuex.vuejs.org/en/actions.html
import axios from "axios";
import moment from "moment";

export default {
  async fetchChartData({ commit }, { params }) {
    try {
      const data = await axios.get('charts/activity', {
        params: params,
      })
      commit('SET_INDICATIONS', data.data)
    }
    catch (error) {
      if (error.response?.data?.message) {
        this.$toast.error(error.response.data.message)
      }
    }
  },
  fetchMonthlyResults ({ commit }, params) {
    commit('setLoading', { key: 'lab_results_monthly', value: true })
    axios.get('charts/lab_results', {
      params: {
        start_date: moment().subtract(11, 'M').startOf('month').format(),
        end_date: moment().endOf('month').format(),
        trend: 28,
        no_cache: params?.no_cache,
      },
    })
      .then((response) => {
        commit('setMonthlyChartData', response.data)
      })
      .catch((error) => {
        console.log(error)
      })
      .finally(() => {
        commit('setLoading', { key: 'lab_results_monthly', value: false })
      })
  },
  fetchDailyResults ({ commit }, params) {
    commit('setLoading', { key: 'lab_results_daily', value: true })
    axios.get('charts/lab_results', {
      params: {
        days: params.days,
        interval: 'daily',
        start_date: moment().subtract(params.days - 1, 'd').startOf('day').format(),
        end_date: moment().format(),
        no_cache: params.no_cache,
      },
    })
      .then((response) => {
        response.data.series.reverse()
        commit('setDailyChartData', response.data)
      })
      .catch((error) => {
        console.log(error)
      })
      .finally(() => {
        commit('setLoading', { key: 'lab_results_daily', value: false })
      })
  },
  fetchMonthlySources ({ commit }, params) {
    commit('setLoading', { key: 'sources', value: true })
    axios.get('charts/sources', {
      params: {
        start_date: moment().subtract(params.days, 'd').format(),
        no_cache: params?.no_cache,
      },
    })
      .then((response) => {
        commit('setSourceChartData', response.data)
      })
      .catch((error) => {
        console.log(error)
      })
      .finally(() => {
        commit('setLoading', { key: 'sources', value: false })
      })
  },
  fetchStatuses ({ commit }) {
    commit('setLoading', { key: 'statuses', value: true })
    axios.get('charts/statuses', {
      params: {
        statuses: [
          'new',
          'data extraction',
          'high confidence',
          'quality assurance',
          'pending approval',
          'do not interpret',
          'unclassified data',
          'needs review',
          'needs translation',
          'contact lab',
          'tech support',
        ],
      },
    })
    .then((response) => {
      commit('setStatusesChartData', response.data)
    })
    .catch(error => {
      if (error.response?.data?.message) {
        this.$toast.error(error.response.data.message)
      }
    })
    .finally(() => {
      commit('setLoading', { key: 'statuses', value: false })
    })
  },
}
